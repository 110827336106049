.company-promo {
    display: inline-block;
    width: 100%;
    margin-bottom: 79px;
	.company-promo-holder {
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
		.promo {
            display: inline-block;
            width: 25%;
            float: left;
            box-sizing: border-box;
            padding: 0 15px;
			.promo-holder {
                display: inline-block;
                width: 100%;
                height: 390px;
                position: relative;
                background: #F5FCF6;
                border-radius: 20px;
                padding: 27px 23px 0;
                box-sizing: border-box;
                overflow: hidden;
				.icon {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 14px;
					svg {

						path {

						}
					}
				}

				.text {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 150%;
				}

				.image {
                    display: inline-block;
                    width: 100%;
                    height: 200px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
					img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/company_promo";
@import "../../media/mobile/includes/index/company_promo";
