@media (max-width: $screen-sm) {
    // sm-view
    .index-bouquets {
        .index-bouquets-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
                margin-bottom: 10px;
            }

            .bouquets-categories {
                margin-bottom: 16px;
            }

            .bouquet-slider {
                padding: 0 0 22px 0;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
