@media (max-width: $screen-lg) {
    // lg-md view
    .index-bouquets {
        overflow: hidden;
        margin: 0 0 39px;
        .index-bouquets-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                margin-bottom: 19px;
            }

            .bouquets-categories {
                .bouquets-categories-holder {
                    display: inline-block;
                    &.slick-slider {
                        .slick-list {
                            .slick-track {
                                .slick-slide {
                                    padding: 0 5px;
                                    >div {

                                    }
                                }
                            }
                        }
                    }


                    a {
                        margin: 0;
                        padding: 9px 19px 10px;
                    }
                }
            }

            .bouquet-slider {
                padding: 0 0 33px 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
