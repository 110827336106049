@media (max-width: $screen-lg) {
    // lg-md view
    .main-slider {
        height: 420px;
        .controlls {
            .controlls-holder {
                height: 420px;
                .prev, .next {

                }

                .next {}

                .nav {
                    ul.slick-dots {
                        bottom: 9px;
                    }
                }
            }
        }

        .main-slider-holder {
            .slide {
                .image {
                    img {}
                }

                .slide-contains {
                    .slide-contains-holder {
                        padding-bottom: 78px;
                        .text {
                            .heading {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 36px;
                                line-height: 150%;
                                margin-bottom: 11px;
                            }

                            .desc {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 150%;
                            }

                        }
                        .text.left {
                            margin-left: -2px;
                        }
                        .text.right {
                            margin-right: -2px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
