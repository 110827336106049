.main-slider {
    display: inline-block;
    width: 100%;
    height: 590px;
    overflow: hidden;
    margin-bottom: -3px;
    &:after {
        content: " ";
        display: inline-block;
        clear: both;
    }
	.controlls {
        .wrapper-inside {
            position: relative;
        }
		.controlls-holder {
            position: absolute;
            width: 100%;
            height: 590px;
			.prev, .next {
                z-index: 5;
                left: -110px;
                transform: translateY(calc(-50% - 5px));
                background: transparent;
                border: 1px solid rgba(84, 91, 99, 0.1);
                color: $gray;
                &:hover {
                    background: $brand-primary;
                    color: white;
                }
                &.slick-disabled {
                    opacity: 0.3;
                    &:hover {
                        background: transparent;
                        color: $gray;
                    }
                }
				svg {

				}
			}

			.next {
                left: auto;
                right: -110px;
			}

			.nav {
                ul.slick-dots {
                    bottom: 24px;
                }
			}
		}
	}

	.main-slider-holder {
        height: 100%;
		.slide {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            color: $gray-base;
			.image {
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
				img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}

			.slide-contains {
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
                .wrapper-inside {
                    height: 100%;
                }
				.slide-contains-holder {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding-bottom: 99px;
					.text {
                        display: inline-block;
                        width: 100%;
                        padding-top: 0;
						.heading {
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 64px;
                            line-height: 130%;
                            margin-bottom: 20px;
						}

						.desc {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 150%;
						}
					}
                    .text.left {
                        display: inline-block;
                        width: 50%;
                        box-sizing: border-box;
                        padding-left: 0;
                        float: left;
                    }
                    .text.right {
                        display: inline-block;
                        width: 50%;
                        float: right;
                        box-sizing: border-box;
                        padding-right: 0;
                    }
				}

			}
		}
	}
}

@import "../../media/tablet/includes/index/main_slider";
@import "../../media/mobile/includes/index/main_slider";
