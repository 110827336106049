@media (max-width: $screen-lg) {
    // lg-md view
    .company-promo-point {
        margin-bottom: 41px;
        .bkg-image {
            img {}
        }

        .company-promo-point-holder {
            padding: 30px 0 30px;
            min-height: 297px;
            .promo-contains {
                width: 57%;
                .heading {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 36px;
                    line-height: 150%;
                    margin-bottom: 9px;
                }

                .text {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
