.catalog-list {
    display: inline-block;
    width: 100%;
    .catalog-list-holder {
        display: inline-block;
        width: calc(100% - 3px);
        .catalog-item {
            width: 33.333%;
            float: left;
        }
        .bouquet {
            width: 33.333%;
            float: left;
        }
    }
}

.catalog-list-block {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
    .catalog-list-block-holder {
        display: inline-block;
        width: 100%;
        .inline-page-action {
            margin: 24px 0 30px;
            .inline-page-action-holder {
                a.btn {
                    min-width: 217px;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/list";
@import "../../media/mobile/includes/catalog/list";
