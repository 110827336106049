@media (max-width: $screen-sm) {
    // sm-view
    .catalog-list {
        .catalog-list-holder {
            .catalog-item {
                width: 100%;
            }
            .bouquet {
                width: 100%;
            }
        }
    }

    .catalog-list-block {
        margin-bottom: 49px;
        .catalog-list-block-holder {
            .inline-page-action {
                margin: 18px 0 19px;
                .inline-page-action-holder {
                    a.btn {
                        min-width: auto;
                        width: 290px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
