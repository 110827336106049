.note {
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
    .note-holder {
        display: inline-block;
        width: 100%;
        .heading {
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 150%;
            margin-bottom: 20px;
        }
        p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
        }
    }
}

@import "../../media/tablet/includes/index/note";
@import "../../media/mobile/includes/index/note";
