.index-bouquets {
    display: inline-block;
    width: 100%;
    margin-bottom: 72px;
	.index-bouquets-holder {
        display: inline-block;
        width: 100%;
		.heading {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 150%;
            margin-bottom: 30px;
		}

		.bouquets-categories {
            display: inline-block;
            width: 100%;
            margin-bottom: 14px;
			.bouquets-categories-holder {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% + 10px);
                margin-left: -5px;
                justify-content: center;
				a {
                    background: transparent;
                    border: 1px solid #DFE5EA;
                    border-radius: 20px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 130%;
                    color: $gray;
                    margin: 0 5px;
                    padding: 9px 20px 12px;
                    margin-bottom: 10px;
                    &:hover {
                        border: 1px solid $brand-primary;
                        background: $brand-primary;
                        color: white;
                    }
                    &.current {
                        border: 1px solid $brand-primary;
                        color: $brand-primary;
                        &:hover {
                            cursor: default;
                        }
                    }
				}
			}
		}

        .bouquet-slider {
            box-sizing: border-box;
            padding: 0 16px 0 19px;
            .slick-slider {
                .slick-list {
                    padding: 4px 2px;
                    box-sizing: border-box;
                    width: calc(100% + 4px);
                    margin-left: -2px;
                    .slick-track {
                        .slick-slide {
                            >div {

                            }
                        }
                    }
                }
            }

            .bouquet-slider-holder {

            }
        }
	}
}

@import "../../media/tablet/includes/index/bouquets";
@import "../../media/mobile/includes/index/bouquets";
