.catalog-list-block {
    display: inline-block;
    width: 100%;
    margin-bottom: 79px;
	.catalog-list-block-holder {
        display: inline-block;
        width: 100%;
		.heading {
            display: inline-block;
            width: 100%;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 150%;
            text-align: center;
            margin-bottom: 40px;
		}

		.inline-page-action {

			.inline-page-action-holder {

				.btn {

				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/catalog_list";
@import "../../media/mobile/includes/index/catalog_list";
