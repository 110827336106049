@media (max-width: $screen-sm) {
    // sm-view
    .how-to-buy {
        .how-to-buy-holder {
            padding: 29px 0 30px;
            >.heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
            }

            .steps {
                width: 100%;
                margin: 0;
                .step {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 55px;
                    &:last-of-type {
                        margin: 0;
                    }
                    .step-holder {
                        .number {

                        }

                        .text {
                            .heading {

                            }

                            .desc {

                            }
                        }

                        .line {
                            transform: translateX(-50%);
                            left: 50%;
                            top: auto;
                            bottom: -42px;
                            .inner-line {
                                width: 1px;
                                height: 30px;
                                border: none;
                                border-left: 1px dashed #959EA8;
                                &:before, &:after {
                                    left: 50%;
                                    transform: translateX(-68%);
                                    bottom: -10px;
                                    top: auto;
                                }
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
