@media (max-width: $screen-sm) {
    // sm-view
    .catalog-list-block {
        .catalog-list-block-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
                margin-bottom: 5px;
            }

            .inline-page-action {
                margin: 20px 0 0;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
