@media (max-width: $screen-sm) {
    // sm-view
    .company-promo {
        .company-promo-holder {
            .promo {
                width: 100%;
                margin: 0 0 8px;
            }
        }
        .inline-page-action {
            margin: 13px 0 0;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
