@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-list-block {
        margin-bottom: 41px;
        .catalog-list-block-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                margin-bottom: 19px;
            }

            .inline-page-action {
                margin: 10px 0 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
