@media (max-width: $screen-sm) {
    // sm-view
    .main-slider {
        height: 120vw;
        .controlls {
            .controlls-holder {
                height: 120vw;
                .prev, .next {

                }

                .next {}

                .nav {
                    ul.slick-dots {
                        bottom: 24px;
                    }
                }
            }
        }

        .main-slider-holder {
            .slide {
                .image {
                    img {}
                }


                .slide-contains {
                    .slide-contains-holder {
                        padding-top: 39px;
                        align-items: flex-start;
                        .text {
                            margin: 0;
                            width: 100%;
                            .heading {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 30px;
                                line-height: 150%;
                                text-align: center;
                                margin: 0;
                            }

                            .desc {
                                display: none;
                            }
                        }
                        .text.left {
                            margin: 0;
                            width: 100%;
                        }
                        .text.right {
                            margin: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
