.company-promo-point {
    display: inline-block;
    width: 100%;
    background-image: url('../img/bkg-back-point.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    position: relative;
    margin-bottom: 82px;
    .bkg-image {
        display: inline-block;
        width: 100%;
        max-width: 1900px;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 1;
        img {
            display: inline-block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
	.company-promo-point-holder {
        display: inline-block;
        width: 100%;
        padding: 133px 0 136px;
		.promo-contains {
            display: inline-block;
            width: 50%;
            float: left;
            position: relative;
            z-index: 2;
			.heading {
                display: inline-block;
                width: 100%;
                font-family: $accent_font;
                font-style: normal;
                font-weight: normal;
                font-size: 56px;
                line-height: 130%;
                margin-bottom: 25px;
			}

			.text {
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                line-height: 150%;
			}
		}
	}
}

@import "../../media/tablet/includes/index/company_promo_point";
@import "../../media/mobile/includes/index/company_promo_point";
