.how-to-buy {
    display: inline-block;
    width: 100%;
    background: #F9F9F9;
    margin-bottom: 0;
	.how-to-buy-holder {
        display: inline-block;
        width: 100%;
        padding: 80px 0 100px;
		>.heading {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            line-height: 150%;
            margin-bottom: 40px;
		}

		.steps {
            display: inline-block;
            width: calc(100% + 32px);
            margin-left: -15px;
			.step {
                display: inline-block;
                float: left;
                width: 33.333%;
                box-sizing: border-box;
                padding: 0 15px;
				.step-holder {
                    display: inline-block;
                    width: 100%;
                    position: relative;
					.number {
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 26px;
						.number-holder {
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background: #00914C;
                            box-shadow: 0px 10px 8px rgba(0, 144, 76, 0.15);
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 40px;
                            color: white;
						}
					}

					.text {
                        display: inline-block;
                        width: 100%;
						.heading {
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 21px;
                            line-height: 150%;
                            margin-bottom: 5px;
						}

						.desc {
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 150%;
						}
					}

					.line {
                        position: absolute;
                        left: calc(100% - 292px/2 + 16px);
                        top: 19px;
                        .inner-line {
                            width: 292px;
                            height: 0;
                            border-top: 1px dashed #959EA8;
                            position: relative;
                            &:before, &:after {
                                content: "";
                                display: inline-block;
                                width: 100%;
                                box-sizing: border-box;
                                width: 8px;
                                height: 8px;
                                border: 1px dashed #959EA8;
                                border-radius: 50%;
                                background: #F9F9F9;
                                position: absolute;
                                left: -5px;
                                top: 0;
                                transform: translateY(-50%);
                            }
                            &:after {
                                left: auto;
                                right: -5px;
                            }
                        }
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/how_to_buy";
@import "../../media/mobile/includes/index/how_to_buy";
