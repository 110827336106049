@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-list {
        .catalog-list-holder {
            width: 100%;
            .catalog-item {

            }
            .bouquet {

            }
        }
    }

    .catalog-list-block {
        margin-bottom: 18px;
        .catalog-list-block-holder {
            .inline-page-action {
                margin: 24px 0 19px;
                .inline-page-action-holder {
                    a.btn {}
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
