@media (max-width: $screen-lg) {
    // lg-md view
    .how-to-buy {
        .how-to-buy-holder {
            padding: 40px 0 100px;
            >.heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                margin-bottom: 19px;
            }

            .steps {
                .step {
                    .step-holder {
                        .number {
                            margin-bottom: 9px;
                        }

                        .text {
                            .heading {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 150%;
                            }

                            .desc {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 150%;
                            }
                        }

                        .line {
                            left: calc(100% - 20vw/2 + 16px);
                            .inner-line {
                                width: 19.4vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
