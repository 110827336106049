@media (max-width: $screen-lg) {
    // lg-md view
    .note {
        margin: 0 0 14px;
        .note-holder {
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
                margin-bottom: 9px;
            }
            p {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
