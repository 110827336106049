@media (max-width: $screen-sm) {
    // sm-view
    .company-promo-point {
        background-image: url('../img/bkg-back-point-xs.png');
        margin-bottom: 34px;
        .bkg-image {
            max-width: 320px;
            height: 69%;
            top: auto;
            bottom: 0;
        }
        .company-promo-point-holder {
            padding: 30px 0 30px;
            min-height: 322px;
            .promo-contains {
                width: 100%;
                .heading {
                    text-align: center;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 150%;
                }

                .text {

                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
