@media (max-width: $screen-lg) {
    // lg-md view
    .company-promo {
        margin-bottom: 41px;
        .company-promo-holder {
            .promo {
                width: 50%;
                margin: 0 0 17px;
                .promo-holder {
                    height: 120px;
                    padding: 0 0 0 20px;
                    display: flex;
                    align-items: center;
                    .icon {
                        display: none;
                    }

                    .text {
                        width: 175px;
                        float: left;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 130%;
                        float: left;
                        text-align: left;
                    }

                    .image {
                        left: auto;
                        right: 0;
                        width: 135px;
                        height: 105px;
                        img {
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        .inline-page-action {
            margin: 0;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
